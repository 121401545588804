import React from 'react'
import { FaAngleRight } from 'react-icons/fa'
import './AffiliatesContent.css'

const AffiliatesContent = ({ data }) => {
  return (
    <div className='affiliate-content'>
        <h2>{data.title}</h2>
        <h4>{data.subtitle}</h4>
        <div className="affiliate-content-cards">
          {data.affiliates.map((item, index) => {
            return (
              <div className="affiliate-content-card" key={index}>
                <div className="left-affiliate">
                  <img src={item.image} alt={item.name} />
                </div>
                <div className="right-affiliate">
                  {
                    item.name.includes('apersi') ? 
                      <h4>{item.name}</h4> : 
                      <h4>{item.name.substring(0, 11)} <br /> {item.name.substring(12)}</h4>
                  }
                  <hr />
                  <p>{item.description}</p>
                  <a href={item.link} rel='noreferrer' target={'_blank'}>Go to Website <FaAngleRight /></a>
                </div>
              </div>
            )
          })}
        </div>
    </div>
  )
}

export default AffiliatesContent