// import slideImg1 from '../assets/images/property-investment.jpg'
// import textBg from '../assets/images/Slider-Home17.png'
import BodImage1 from '../assets/images/DSC_6635C.jpg'
import BodImage2 from '../assets/images/DSC_6638A.jpg'
import HoTeamImg from '../assets/images/DSC_6621.jpg'
import EngTeamImg from '../assets/images/DSC_6309.jpg'
import BoTeamImg from '../assets/images/DSC_6252.jpg'
import MarketTeamImg from '../assets/images/DSC_6290.jpg'
import CsImg from '../assets/images/customer-service.jpg'
import FasadImg from '../assets/images/fasad-gedung.jpeg'

export const about = {
    // slides: [
    //     {
    //         img: slideImg1,
    //         text1: 'About Us',
    //         text2: 'Praja Sakti Propertindo',
    //         textBg: textBg
    //     },
    // ],
    section: {
        title: 'About Us',
        img: FasadImg,
        text: ['PT Praja Sakti Propertindo merupakan salah satu perusahaan pengembang properti ' + 
            'yang berpengalaman, profesional dan berkualitas baik dalam bidang marketing, ' + 
            'konstruksi dan perbankan. Salah satu proyek yang sedang kami kembangkan adalah ' + 
            '"Perumahan Kebun Kembang Asri" dengan mencakup potensi lahan seluas 29 Ha di Cikampek, ' + 
            'Karawang Jawa Barat. Pengembangan dibagi 3 tahap, dimana tahap pertama ' + 
            'telah menghasilkan akad kredit tertinggi hingga mencapai lebih dari 700 unit ' + 
            'yang dapat dilaksanakan dalam 2 tahun pertama launching dan telah 90% terhuni. ' + 
            'Untuk ke depannya pengembangan hunian akan berpotensi sebanyak lebih dari 2200 unit. ' + 
            'Dengan komitmen dan konsistensi yang dimiliki, kami menjadi salah satu developer terbaik ' + 
            'yang dipilih oleh Kementerian Pekerjaan Umum dan Perumahan Rakyat untuk ' + 
            'mendapatkan dukungan pembangunan fasilitas umum perumahan.',
            'Menghadirkan tim yang ahli dan berpengalaman di bidangnya, kami dapat mewujudkan ' + 
            'hunian yang tertata rapih, indah dan nyaman. Kami memiliki tujuan untuk terus ' + 
            'melakukan pengembangan dengan ekspansi ke berbagai lokasi di Jawa Barat. ' + 
            'Dengan memberikan performa kualitas bangunan terbaik melalui material ' + 
            'yang digunakan dan berbagai kelengkapan fasilitas yang dihadirkan dengan ' + 
            'harga yang terjangkau dan cicilan ringan bagi seluruh lapisan masyarakat.']
    },
    visionMission: [
        {
            title: 'Our Vision',
            texts: [
                'Menjadi pengembang yang kokoh dan terdepan dalam menyediakan hunian berkualitas dengan harga terjangkau, serta melalui proyek-proyek strategis mampu membangun perekonomian daerah dan memberikan nilai tambah bagi konsumen, rekan usaha, pemegang saham, investor, dan berbagai lapisan masyarakat.'
            ]
        },
        {
            title: 'Our Mission',
            texts: [
                'Menyediakan kawasan hunian yang strategis, nyaman, berkualitas dengan harga terjangkau.',
                'Mengedepankan inovasi, good corporate governance, dan efisiensi dalam pelaksanaan bisnis proses perusahaan.',
                'Mengembangkan organisasi dan sumber daya manusia yang tangguh, sejahtera, serta mengedepankan kinerja dan integritas.',
                'Mengembangkan persediaan tanah (land bank) yang strategis untuk menjaga kelangsungan proyek jangka panjang.',
                'Mengoptimalkan potensi setiap proyek pengembangan dalam rangka menjaga profitabilitas dan menghasilkan investasi yang menguntungkan bagi rekan usaha, investor, dan pemegang saham.',
                'Bersinergi dengan pemerintah daerah untuk program kesejahteraan masyarakat di lokasi-lokasi proyek pengembangan.'
            ]
        }
    ],
    bod: {
        image1: BodImage1,
        image2: BodImage2
    },
    teams: {
        title: 'Our Team',
        subtitle: 'Profesional, Berkualitas dan Integritas',
        text: 'Tim yang kami hadirkan mampu memberikan yang terbaik dalam mewujudkan kepuasan Anda.',
        teams: [
            {
                teamImg: HoTeamImg,
                teamName: 'Head Office',
                teamDescription: 'Tim office yang bertugas di kantor pusat yang akan membantu mengelola seluruh aktivitas bisnis.'
            },
            {
                teamImg: EngTeamImg,
                teamName: 'Sipil dan Engineering',
                teamDescription: 'Hadirkan tenaga kerja yang ahli dan berpengalaman di bidang konstruksi mampu memberikan bangunan yang berkualitas dengan desain yang bagus, sesuai dengan impian Anda.'
            },
            {
                teamImg: BoTeamImg,
                teamName: 'Back Office',
                teamDescription: 'Tim yang bertugas mensupport administrasi KPR, legalitas, dan memberikan kenyamanan bagi konsumen.'
            },
            {
                teamImg: MarketTeamImg,
                teamName: 'Marketing',
                teamDescription: 'Menghadirkan team marketing yang siap membantu dan mengerti pada kebutuhan Anda.'
            },
        ]
    },
    formContent: {
        image: CsImg
    }
}