import React from 'react'
import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'
import Slider from '../components/Slider/Slider'
import CTA from '../components/CTA/CTA'
import TypeContent from '../components/TypeContent/TypeContent'
import BenefitContent from '../components/BenefitContent/BenefitContent'
import VideoContent from '../components/VideoContent/VideoContent'
import CareerContent from '../components/CareerContent/CareerContent'
import FormContent from '../components/FormContent/FormContent'
import WhatsAppBtn from '../components/WhatsAppBtn/WhatsAppBtn'
import ScrollToTop from '../components/ScrollToTop/ScrollToTop'
import SectionContent from '../components/SectionContent/SectionContent'
import VisionMissionContent from '../components/VisionMissionContent/VisionMissionContent'
import TeamContent from '../components/TeamContent/TeamContent'
import BodImagesSection from '../components/BodImagesSection/BodImagesSection'
import AffiliatesContent from '../components/AffiliatesContent/AffiliatesContent'
import PartnersContent from '../components/PartnersContent/PartnersContent'
import BeOurPartnerContent from '../components/BeOurPartnerContent/BeOurPartnerContent'
import ContactContent from '../components/ContactContent/ContactContent'
import DirectorGalleryContent from '../components/DirectorGalleryContent/DirectorGalleryContent'

const MainLayout = ({ pageTitle, data, isUsingType }) => {
  return (
    <div style={{ maxWidth: '100vw', overflow: 'hidden' }}>
      <Navbar />
      {data.slides ? <Slider slides={data.slides} /> : <div style={{ height: '70px' }}></div> }
      {data.cta ? <CTA text={data.cta} showDownloadBrochure={true}  /> : null}
      {isUsingType ? <TypeContent data={data.typeContent} /> : null}
      {data.directorsImages ? <DirectorGalleryContent data={data.directorsImages} /> : null}
      {data.benefitContent ? <BenefitContent data={data.benefitContent} /> : null}
      {data.section ? <SectionContent data={data.section} /> : null}
      {data.bod ? <BodImagesSection data={data.bod} /> : null}
      {data.visionMission ? <VisionMissionContent data={data.visionMission} /> : null}
      {data.teams ? <TeamContent data={data.teams} /> : null}
      {data.videoContent ? <VideoContent data={data.videoContent} /> : null}
      {data.careerContent ? <CareerContent data={data.careerContent} /> : null}
      {data.affiliatesSection ? <AffiliatesContent data={data.affiliatesSection} /> : null}
      {data.partnersSection ? <PartnersContent data={data.partnersSection} /> : null}
      {data.beOurPartnerSection ? <BeOurPartnerContent data={data.beOurPartnerSection} /> : null}
      {data.contactSection ? <ContactContent /> : null}
      {data.formContent ? <FormContent data={data.formContent} /> : null}
      <Footer />
      <WhatsAppBtn />
      <ScrollToTop />
    </div>
  )
}

export default MainLayout