import ImgBogorEvent from '../assets/images/bogor-event-00.jpg'
import ImgBtnEvent0 from '../assets/images/btn-event-00.jpg'
import ImgBtnEvent1 from '../assets/images/btn-event-01.jpg'
import ImgBtnEvent2 from '../assets/images/btn-event-02.jpg'

export const events = {
    events: [
        // {
        //     title: '17an Perumahan Kebun Kembang Asri',
        //     text: 'Pada hari Sabtu 31 Agustus 2019 diadakan acara dalam rangka memperingati HUT RI di perumahan Kebun Kembang Asri diikuti oleh karyawan-karyawan PT Praja Sakti Propertindo.',
        //     image: '',
        //     dateCreated: '2019-08-07',
        //     type: 'event'
        // },
        // {
        //     title: 'Raker Target Tahap II',
        //     text: 'Pada hari Senin 02 September 2019 diadakan rapat kerja tahunan untuk mencapai target Tahap II, diadakan di hotel Eighteen Jakarta Pusat dihadiri seluruh karyawan dan managament PT Praja Sakti Propertindo.',
        //     image: [],
        //     dateCreated: '2019-09-02',
        //     type: 'event'
        // },
        // {
        //     title: 'Akad Kredit Masal Konsumen Perumahan Kebun Kembang Asri',
        //     text: 'Pada hari Rabu 19 Februari 2020 diadakan akad kredit masal konsumen Perumahan Kebun Kembang Asri dengan Bank BTN Bekasi yang dilaksanakan di kantor pemasaran.',
        //     image: [],
        //     dateCreated: '2020-02-19',
        //     type: 'event'
        // },
        // {
        //     title: 'Pembagian Bantuan Bagi Korban Bencana Banjir di Daerah Cikampek Karawang Jawa Barat',
        //     text: 'Management PT Praja Sakti Propertindo pada hari Rabu tanggal 26 Februari 2020 memberikan bantuan bagi korban bencana banjir di daerah Cikampek Karawang Jawa Barat, berupa Beras, Mie Instan, Gula, Kopi dan berbagai kebutuhan pokok lainnya. Management PT Praja Sakti Propertindo menunjukan kepedulian terhadap para korban bencana Banjir yang terdampak.',
        //     image: [],
        //     dateCreated: '2020-02-26',
        //     type: 'event'
        // },
        // {
        //     title: 'Akad Kredit Masal Konsumen Perumahan Kebun Kembang Asri',
        //     text: 'Pada Hari Selasa 09 April 2020 diadakan akad kredit masal konsumen Perumahan Kebun Kembang Asri dengan Bank BTN Bekasi yang dilaksanakan di kantor pemasaran.',
        //     image: [],
        //     dateCreated: '2020-04-09',
        //     type: 'event'
        // },
        // {
        //     title: 'Goes To Malaysia',
        //     text: 'Pada Hari Sabtu 13 April – Senin 15 April 2019 diadakan acara tour Praja Sakti Propertindo Goes To Malaysia bersama management dan karyawan berlibur 3 hari 2 malam di Malaysia sebagai reward bagi karyawan–karyawan. (Foto Event XI Terlampir).',
        //     image: [],
        //     dateCreated: '2020-04-15',
        //     type: 'event'
        // },
        // {
        //     title: 'Pembagian Bantuan APD (Alat Pelindung Diri) dan Face Shield Bagi Tenaga Medis dari Management PT Praja Sakti Propertindo untuk Dibagikan ke Puskesmas dan Rumah Sakit di Wilayah Cikampek Karawang Jawa Barat',
        //     text: 'Pada hari Sabtu tanggal 18 April 2020 Management PT Praja Sakti Propertindo Perumahan Kebun Kembang Asri memberikan bantuan APD (Alat Pelindung Diri) dan Face Shield Bagi Tenaga Medis di beberapa Puskesmas dan Rumah Sakit di Wilayah Cikampek Karawang Jawa Barat. (Foto Event V Terlampir).',
        //     image: [],
        //     dateCreated: '2020-04-18',
        //     type: 'event'
        // },
        // {
        //     title: 'Akad Kredit Masal Konsumen Perumahan Kebun Kembang Asri',
        //     text: 'Pada hari Selasa 21 April 2020 diadakan akad kredit masal konsumen Perumahan Kebun Kembang Asri dengan Bank BTN Bekasi yang dilaksanakan di kantor pemasaran.',
        //     image: [],
        //     dateCreated: '2020-04-22',
        //     type: 'event'
        // },
        // {
        //     title: 'Miliki Rumah Terbaik Untuk Kenyamanan Anda',
        //     text: [
        //         'Rumah menjadi aset bagi kebanyakan keluarga, khususnya bagi pasangan muda yang masih awal dalam menjalankan berumah tangga.Dalam memilih suatu rumah di sebuah perumahan ada banyak yang patut untuk di perhatikan.Salah satunya memilih posisi dan letak rumah.Karena, memang tidak dipungkiri bahwa letak rumah menjadi salah satu hal yang penting dan sangat menentukan apakah Anda bisa nyaman dan mendukung setiap kegiatan sehari-hari.',
        //         'Tentukan dengan baik saat memilih perumahan, Kebun Kembang Asri menjadi salah satu pilihan tepat untuk Anda memiliki kawasan perumahan dan letak rumah.Karena menghadirkan pilihan rumah yang ideal, bagus dan memberikan kenyamanan saat tinggal di kawasan tersebut. Bagi Anda yang mencari perumahan, ada baiknya untuk langsung memilih perumahan Kebun Kembang Asri yang memiliki kenyamanan tersendiri. Berikut ini adapun tip memilih letak dan posisi rumah yang tepat.',
        //         '1. Akses Jalan yang Bagus',
        //         'Akses jalan menjadi hal yang penting bagi setiap orang, pilihkan jalan di area perumahan yang baik, karena jalanan dengan kondisi buruk tidak layak untuk didapatkan penghuni perumahan. Untuk itu ada baiknya cari perumahan yang aksesnya mudah ditemui.',
        //         '2. Area Lingkungan Perumahan yang Aman',
        //         'Sebaiknya Anda mencari letak rumah yang aman, biasanya perumahan yang sudah jadi sudah ada security nya yang bertugas menjaga keamanan pada suatu lingkungan perumahan.',
        //         '3. Kriteria Keterjangkauan Jarak',
        //         'Mempertimbangkan jarak pencapaian ideal kemampuan orang berjalan kaki sebagai pengguna lingkungan terhadap penempatan sarana dan prasarana - utilitas lingkungan.',
        //         'Tidak hanya beberapa point di atas, jangan lupa untuk Anda memahami kualitas bangunan yang ditawarkan, setidaknya bisa dinilai dari rumah contoh yang disediakan. Jadi utamakan letak, akses, kualitas bangunan demi kenyamanan aktivitas Anda.'
        //     ],
        //     image: [],
        //     dateCreated: '2020-04-30',
        //     type: 'article'
        // },
        // {
        //     title: 'Goes To Bali',
        //     text: 'Pada Hari Kamis 03 Mei – Senin 06 Mei 2018 diadakan acara tour Praja Sakti Propertindo Goes To Bali bersama management dan karyawan berlibur 4 hari 2 malam di Bali sebagai reward bagi karyawan–karyawan. (Foto Event XI Terlampir).',
        //     image: [],
        //     dateCreated: '2020-05-06',
        //     type: 'event'
        // },
        // {
        //     title: 'Akad Kredit Masal Konsumen Perumahan Kebun Kembang Asri',
        //     text: 'Pada hari Senin 18 Mei 2020 diadakan akad kredit masal konsumen Perumahan Kebun Kembang Asri dengan Bank BTN Bekasi yang dilaksanakan di kantor pemasaran.',
        //     image: [],
        //     dateCreated: '2020-05-18',
        //     type: 'event'
        // },
        // {
        //     title: 'Buka Puasa Bersama Management PT Praja Sakti Propertindo dengan seluruh Karyawan dan Karyawati',
        //     text: 'Pada hari Kamis 30 Mei 2019 diadakan acara buka puasa bersama Mangement PT Praja Sakti Propertindo dengan Seluruh Karyawan dan Karyawati di Rumah Makan Ever Fresh Benhil Jakarta Pusat.',
        //     image: [],
        //     dateCreated: '2020-05-30',
        //     type: 'event'
        // },
        // {
        //     title: 'Pembagian Hadiah Perlengkapan Rumah Tangga bagi Konsumen yang telah Booking di Perumahan Kebun Kembang Asri',
        //     text: 'Management Perumahan Kebun Kembang Asri sering membagikan hadiah berupa peralatan rumah tangga bagi konsumen yang telah booking di Perumahan Kebun Kembang Asri.',
        //     image: [],
        //     dateCreated: '2020-06-23',
        //     type: 'event'
        // },
        // {
        //     title: 'Peresmian Tahap II Perumahan Kebun Kembang Asri',
        //     text: 'Pada hari Sabtu tanggal 29 Juni 2019 diadakan acara Peresmian Tahap II Perumahan Kebun Kembang Asri setelah sebelumnya telah sold 95% dan sudah terhuni 98% tahap I Perumahan Kebun Kembang Asri Desa Cikampek Timur dan Desa Sarimulya Kecamatan Cikampek dan Kotabaru Kabupaten Karawang Jawa Barat.',
        //     image: [],
        //     dateCreated: '2020-07-29',
        //     type: 'event'
        // },
        // {
        //     title: 'Pembagian Hewan Qurban 8 Ekor Kambing untuk Dibagikan ke Beberapa Wilayah Sekitar Perumahan Kebun Kembang Asri',
        //     text: 'Pada hari Jumat tanggal 31 Juli 2020 Management PT Praja Sakti Propertindo, Perumahan Kebun Kembang Asri membagikan 8 ekor hewan Qurban kambing untuk dibagikan ke beberapa wilayah sekitar Perumahan Kebun Kembang Asri, agar dapat memberikan berkah dan manfaat bagi warga yang membutuhkan.',
        //     image: [],
        //     dateCreated: '2020-07-31',
        //     type: 'event'
        // },
        // {
        //     title: 'Tips Memilih Perumahan Yang Terbaik dan Berkualitas',
        //     text: [
        //         'Memiliki rumah sendiri memang menjadi salah satu idaman bagi semua orang baik yang belum bekeluarga atau yang sudah bekeluarga.Karena rumah menjadi salah satu tempat bersinggah bagi setiap orang untuk beristirahat dan melakukan berbagai aktivitas dengan tenang dan damai, menjadi tempat berlindung bagi semua orang. Jadi sudah pasti sebagian orang memilih mencari kawasan hunian yang terbaik yang nyaman untuk disinggahi.',
        //         'Rumah idaman menjadi salah satu pilihan tepat untuk kebahagiaan keluarga, di antaranya rumah yang berada di tengah-tengah kota dan akses yang mudah untuk ke mana-mana seperti yang dimiliki perumahan Kebun Kembang Asri yang mampu menghadirkan perumahan yang terbaik, lokasi stategis, dan mudah untuk diakses ke manapun yang meliputi kawasan industri, kawasan pasar, kawasan mall cikampek, rumah sakit dan lain sebagainya. Perumahan Kebun Kembang Asri memberikan yang terbaik untuk memudahkan penghuni dalam menjalankan aktivitas. Adapun beberapa tips memilih perumahan terbaik.',
        //         '1. Fasilitas Pendukung',
        //         'Rumah tidak hanya berbicara tempat tinggal, tetapi juga bagaimana bisa hidup nyaman dalam lingkungan perumahan. Karena itu, fasilitas pendukung menjadi krusial.',
        //         '2. Metode Pembayaran',
        //         'Perumahan yang memberikan berbagai kemudahan dalam cara pembayaran untuk menjadi alternatif bagi para calon pembeli rumah.',
        //         '3. Dapat Memudahkan Kebutuhan Para Penghuni',
        //         'Satu hal yang paling penting saat memilih kawasan perumahan adalah bagaimana perumahan itu mampu memfasilitasi Anda nantinya, seperti apa lingkungannya, dan bagaimana perumahan bisa memberikan kemudahan bagi untuk Anda dalam memenuhi kebutuhan sehari - hari.',
        //     ],
        //     image: [],
        //     dateCreated: '2020-08-07',
        //     type: 'article'
        // },
        // {
        //     title: 'Invitation Customer Gathering',
        //     text: 'Management Perumahan Kebun Kembang Asri selalu mengadakan acara customer gathering di rumah contoh tahap II, Bagi konsumen yang datang mendapatkan souvenir menarik, dan bagi konsumen yang booking mendapatkan penawaran terbaik.',
        //     image: [],
        //     dateCreated: '2020-08-07',
        //     type: 'event'
        // },
        // {
        //     title: 'Akad Kredit Masal Konsumen Perumahan Kebun Kembang Asri',
        //     text: 'Pada hari Kamis 27 Agustus 2020 diadakan akad kredit masal konsumen Perumahan Kebun Kembang Asri dengan Bank BTN Bekasi yang dilaksanakan di kantor pemasaran.',
        //     image: [],
        //     dateCreated: '2020-08-27',
        //     type: 'event'
        // },
        // {
        //     title: 'Pembagian Masker Gratis di Perumahan Kebun Kembang Asri',
        //     text: 'Pada hari Sabtu tanggal 29 Agustus 2020 management PT Praja Sakti Propertindo Perumahan Kebun Kembang Asri, membagikan masker gratis bagi warga yang keluar masuk perumahan, dalam rangka sosialisasi mengenai pencegahan penyebaran covid 19 di wilayah Cikampek Kabupaten Karawang Jawa Barat.',
        //     image: [],
        //     dateCreated: '2020-08-29',
        //     type: 'event'
        // },
        {
            title: 'Hunian Asri-Terjangkau PT Astana Trilaras Jaya untuk Milenial di Bogor',
            text: 'Anak perusahaan dari PT Praja Sakti Propertindo, PT Astana Trilaras Jaya, menjadikan tahun 2023 sebagai momentum untuk membangun proyek perumahan baru bernama Neo Green Residence di Kawasan Tanah Baru, Kecamatan Bogor Utara, Kota Bogor, Jawa Barat.',
            thumbnail: ImgBogorEvent,
            image: [ImgBogorEvent],
            dateCreated: '2023-03-20',
            type: 'event',
            link: 'https://finance.detik.com/properti/d-6614947/hunian-asri-terjangkau-pt-astana-trilaras-jaya-untuk-milenial-di-bogor',
        },
        {
            title: 'BTN Beri Penghargaan untuk Mitra Pengembang',
            text: 'PT Bank Tabungan Negara Tbk. (BTN) memberikan awards atau penghargaan terhadap puluhan mitra pengembang yang dinilai sukses mencapai target penyaluran kredit kepemilikan rumah (KPR) sebesar Rp100 miliar dalam setahun melalui penghargaan 100 Billion Achievement Award 2023 pada Kamis, (9/2). Acara ini menjadi rangkaian dari kegiatan ulang tahun BTN ke 73.',
            thumbnail: ImgBtnEvent0,
            image: [ImgBtnEvent0, ImgBtnEvent1, ImgBtnEvent2],
            dateCreated: '2023-02-11',
            type: 'event',
            link: 'https://mediaindonesia.com/ekonomi/557565/btn-beri-penghargaan-untuk-mitra-pengembang#:~:text=PT%20Bank%20Tabungan%20Negara%20Tbk,%2C%20(9%2F2).',
        },
    ]
}