import React from 'react'
import './VisionMissionContent.css'

const VisionMissionContent = ({ data }) => {
  return (
    <div className='vision-mission'>
      {
        data.map((item, index) => {
          return (
            <div className='vission-section' key={index}>
              <div className="section-title"><h3>{item.title}</h3></div>
              <div className="section-content">
                {item.texts.length > 1 ? (
                  <ul>
                    {item.texts.map((data, index) => <li key={index}>{data}</li>)}
                  </ul>
                ) : <p>{item.texts[0]}</p>}
              </div>
            </div>)
      })
      }
    </div>
  )
}

export default VisionMissionContent