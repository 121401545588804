import React, { useEffect } from 'react'
import ProjectLayout from '../../../../layout/ProjectLayout'
import { kka13672 } from '../../../../data/kka-13672'

export const Type3672 = () => {
  useEffect(() => {
    document.title = 'KEBUN KEMBANG ASRI 1 - Type 36/72 - Website Praja'
  }, [])
  return (
    <ProjectLayout data={kka13672} />
  )
}
