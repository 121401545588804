import slideImg1 from '../assets/images/property-agent.jpg'
import textBg from '../assets/images/Slider-Home17.png'
import BtnSyariahImg from '../assets/images/BTN-Syariah.png'
import BtnImg from '../assets/images/BTN.png'
import BniImg from '../assets/images/BNI.png'
import BniSyariahImg from '../assets/images/BNI-Syariah.png'
import MandiriImg from '../assets/images/Mandiri_logo.png'
import PuprImg from '../assets/images/pupr.jpg'
import ApersiImg from '../assets/images/logo-apersi.png'
import PropertyImg from '../assets/images/property-deal.jpg'
import PropertyAgentImg from '../assets/images/property-agent.jpg'
import PropertyInvestmentImg from '../assets/images/property-investment.jpg'
import CsImg from '../assets/images/customer-service.jpg'

export const partnership = {
    slides: [
        {
            img: slideImg1,
            text1: 'Partnership',
            text2: 'Praja Sakti Propertindo',
            textBg: textBg
        },
    ],
    affiliatesSection: {
        title: 'Affiliates',
        subtitle: 'Perusahaan kami telah bekerja sama pada lembaga Apersi dan Kementrian PU- PR, sehingga dapat mengembangkan bisnis secara tepat.',
        affiliates: [
            {
                image: PuprImg,
                name: 'Kementerian PU - PR',
                description: 'Kebun Kembang Asri pernah mendapat bantuan PSU pada tahun 2018 untuk Unit subsidi sehingga dapat meningkatkan efektifitas dan efisiensi bagi masyarakat.',
                link: 'https://www.pu.go.id/'
            },
            {
                image: ApersiImg,
                name: 'APERSI',
                subName: 'Tergabung Dalam APERSI DPD Jawa Barat',
                description: 'PT Praja Sakti Propertindo menjadi salah satu perusahaan pengembang properti yang telah terdaftar menjadi salah satu anggota APERSI.',
                link: 'https://apersi-jabar.or.id/'
            }
        ],
    },
    partnersSection: {
        title: 'Our Partners',
        subtitle: 'Berpengalaman dalam mengelola pengembangan properti menjadi kawasan perumahan yang banyak diminati masyarakat. Berikut ini beberapa partner yang telah bekerja sama dengan kami.',
        partners: [MandiriImg, BtnImg, BtnSyariahImg, BniImg, BniSyariahImg]
    },
    beOurPartnerSection: {
        title: 'Be Our Partners',
        subtitle: 'Bergabunglah bersama kami untuk mendapatkan kawasan hunian premium yang menjadi peluang besar untuk Anda memenuhi kehidupan yang lebih optimal.',
        partnerType: [
            {
                image: PropertyImg,
                title: 'Partner Lahan / Properti',
                description: 'Semua lahan, tanah, dan kavling memiliki potensi masing-masing. Namun tidak semua orang dapat mengembangkannya secara optimum. Seringkali lahan atau properti tersebut dibiarkan tidak diolah dan malah menjadi beban biaya (Pajak PBB, pemeliharaan, dll). Dengan pengalaman kami dan tim yang ahli di bidangnya, kami percaya dapat menambah nilai dan memaksimalkan potensi lahan tersebut melalui proyek-proyek yang relevan. Hubungi kami apabila Anda memiliki lahan untuk dijual maupun dikerjasamakan dan ubah aset Anda menjadi keuntungan.'
            },
            {
                image: PropertyAgentImg,
                title: 'Member Pemasaran',
                description: 'Siapapun dapat menjadi sukses asalkan giat berusaha dan terus menggali potensi diri. Dengan perputaran proyek yang cepat dan produk yang banyak, Anda dapat memaksimalkan keuntungan dengan pasti dengan bergabung menjadi Member Pemasaran kami.'
            },
            {
                image: PropertyInvestmentImg,
                title: 'Peluang Investasi',
                description: 'Berinvestasi pada aset-aset properti sangatlah tepat terutama untuk Anda yang ingin mendapatkan keuntungan dan keamanan investasi dalam jangka panjang. Salah satu caranya adalah dengan berinvestasi pada properti di lokasi yang sedang berkembang dan menjualnya saat harga properti di kawasan tersebut telah matang. Hubungi tim kami dan dapatkan penawaran terbaik untuk pembelian dengan jumlah banyak (bulk sales) ataupun pembelian awal saat launching (early bird).'
            }
        ]
    },
    formContent: {
        image: CsImg
    }
}