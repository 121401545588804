import React from 'react'
import { ImAccessibility } from 'react-icons/im'
import { BiMapPin, BiTimer } from 'react-icons/bi'
import { BsBuilding, BsShieldFillCheck, BsFillGrid3X3GapFill } from 'react-icons/bs'
import { FaPray } from 'react-icons/fa'
import { MdPayments } from 'react-icons/md'
import './ProjectAdvantages.css'

const ProjectAdvantages = () => {
  return (
    <div className='advantages-section'>
        <h2>Keunggulan Project</h2>
        <span>Dalam memenuhi kepuasan customer, kami memberikan berbagai layanan yang menjadi keunggulan kami.</span>
        <div className="advantages-cards">
            <div className="card">
                <div className="title">
                    <div className="icon"><ImAccessibility /></div>
                    <h4>Akses Mudah</h4>
                </div>
                <span className="desc">Perumahan yang dapat diakses dari berbagai jalur utama, 10 menit dari exit tol cikampek. Jalanan yang lebar memudahkan Anda beraktifitas.</span>
            </div>
            <div className="card">
                <div className="title">
                    <div className="icon"><BiMapPin /></div>
                    <h4>Lokasi Strategis</h4>
                </div>
                <span className="desc">Berada di tengah kota cikampek di antara 2 kawasan industri besar Bukit Indah City dan Pupuk Kujang, dekat dengan pasar dan pusat perbelanjaan (Mall) Layanan kesehatan (Rumah Sakit), dan Pendidikan (sekolah).</span>
            </div>
            <div className="card">
                <div className="title">
                    <div className="icon"><BsBuilding /></div>
                    <h4>Kawasan Industri</h4>
                </div>
                <span className="desc">Dekat dengan kawasan industri sehingga memudahkan Anda menghemat banyak waktu, tenaga, dan uang ketika berangkat ataupun pulang kerja.</span>
            </div>
            <div className="card">
                <div className="title">
                    <div className="icon"><FaPray /></div>
                    <h4>Fasilitas Kesehatan & Tempat Ibadah</h4>
                </div>
                <span className="desc">Dekat dengan berbagai fasilitas kesehatan dan tempat ibadah sehingga memudahkan keluarga dalam melakukan aktivitas.</span>
            </div>
            <div className="card">
                <div className="title">
                    <div className="icon"><MdPayments /></div>
                    <h4>Beragam Cara Bayar Ringan</h4>
                </div>
                <span className="desc">Memberikan kemudahan konsumen dalam melakukan pembayaran melalui beragam cara seperti cash keras, cash bertahap, maupun kredit KPR mulai 1 jtan perbulan sudah dapat memiliki rumah impian.</span>
            </div>
            <div className="card">
                <div className="title">
                    <div className="icon"><BsShieldFillCheck /></div>
                    <h4>Kualitas Bangunan</h4>
                </div>
                <span className="desc">Memberikan kualitas bangunan yang baik dengan desain rumah yang dapat disesuaikan dengan kenyamanan keluarga Anda.</span>
            </div>
            <div className="card">
                <div className="title">
                    <div className="icon"><BiTimer /></div>
                    <h4>Pembangunan Cepat</h4>
                </div>
                <span className="desc">Mengerjakan proses pembangunan rumah dengan cepat, tepat waktu tanpa mengurangi kualitas yang diberikan, agar dapat segera dihuni oleh Anda dan keluarga.</span>
            </div>
            <div className="card">
                <div className="title">
                    <div className="icon"><BsFillGrid3X3GapFill /></div>
                    <h4>Beragam Type Unit</h4>
                </div>
                <span className="desc">Tersedia beragam type unit yang dapat disesuaikan dengan kebutuhan Anda, mulai dari tipe 27/60, 30/60, 30/72, 36/72, 45/84 dan lain-lain.</span>
            </div>
        </div>
    </div>
  )
}

export default ProjectAdvantages