import React from 'react'
import './TeamContent.css'

const TeamContent = ({ data }) => {
  return (
    <div className='team-section'>
        <h4>{data.title}</h4>
        <h2>{data.subtitle}</h2>
        <p>{data.text}</p>
        <div className="teams">
            {data.teams.map((item, index) => {
                return <div key={index} className="team">
                    <img src={item.teamImg} alt={item.teamName} />
                    <p className="team-name">{item.teamName}</p>
                    <p className="team-description">{item.teamDescription}</p>
                </div>
            })}
        </div>
    </div>
  )
}

export default TeamContent