import React from 'react'
import './BenefitContent.css'

const BenefitContent = ({ data }) => {
  return (
    <div className='benefit-content'>
      <h4>{data.title}</h4>
      <h2>{data.subtitle}</h2>
      <p>{data.text}</p>
      <div className='benefits'>
        {data && data.benefits ? data.benefits.map((item, index) => (
          <div key={index} className='benefit'>
            <div className="icon">{item.icon}</div>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
        )) : null}
      </div>
    </div>
  )
}

export default BenefitContent