import slideImg1 from '../assets/images/KKA-2-1. Proses KPR Cepat dan Mudah.jpg'
import slideImg2 from '../assets/images/KKA-2-2. Jaminan Investasi yang Menguntungkan.jpg'
import slideImg3 from '../assets/images/KKA-2-3. Siap Huni dengan Kwalitas Terbaik.jpg'
import slideImg4 from '../assets/images/KKA-2-4. Memberikan Kenyamanan Bagi Keluarga Anda.jpg'
import slideImg5 from '../assets/images/KKA-2-5. Memberikan Kenyamanan Bagi Keluarga Anda.jpg'
import slideImg6 from '../assets/images/KKA-2-6. Memberikan Kenyamanan Bagi Keluarga Anda.jpg'
import textBg from '../assets/images/Slider-Home17.png'
import LeaveBg from '../assets/images/leave-bg.jpg'
import Kka23672Img from '../assets/images/Blok C7 Type 36-72.jpg'
import Kka23060Img from '../assets/images/Blok H Type 30-60.jpg'
import Kka23056Img from '../assets/images/Blok J1 Type 30-56.jpg'
import Kka2e3672Img from '../assets/images/Blok E Type 36-72.jpg'
import Kka2f3672Img from '../assets/images/Blok F Type 36-72.jpg'
import Kka23072Img from '../assets/images/Blok J2 Type 30-72.jpg'
import PlanImg from '../assets/images/spek-denah-30-56.png'
import PlanImgMobile from '../assets/images/spek-denah-30-56-mobile.png'
import slideProject1 from '../assets/images/J1-Gambar Fisik 1.jpg'
import slideProject2 from '../assets/images/J1-Gambar Fisik 2.jpg'
import slideProject3 from '../assets/images/J1-Gambar Fisik 3.jpg'
import slideProject4 from '../assets/images/J1-Gambar Fisik 4.jpg'
import slideProject5 from '../assets/images/J1-Gambar Fisik 5.jpg'

export const kka23056 = {
    slides: [
        {
            img: slideImg1,
            text1: 'Proses KPR Cepat dan Mudah',
            textBg: textBg
        },
        {
            img: slideImg2,
            text1: 'Jaminan Investasi yang Menguntungkan',
            textBg: textBg
        },
        {
            img: slideImg3,
            text1: 'Siap Huni dengan Kwalitas Terbaik',
            textBg: textBg
        },
        {
            img: slideImg4,
            text1: 'Memberikan Kenyamanan Bagi Keluarga Anda',
            textBg: textBg
        },
        {
            img: slideImg5,
            text1: 'Memberikan Kenyamanan Bagi Keluarga Anda',
            textBg: textBg
        },
        {
            img: slideImg6,
            text1: 'Memberikan Kenyamanan Bagi Keluarga Anda',
            textBg: textBg
        },
    ],
    achievementSection: {
        title: 'Project Achievement',
        sectionBg: LeaveBg,
        achievements: [
            {
                number: 98,
                postFix: '%',
                title: 'Sold',
                description: 'Rumah impian yang banyak diminati karena letaknya yang strategis dan harga yang terjangkau.'
            },
            {
                number: 700,
                title: 'Akad dalam 2 Tahun',
                description: 'Tercapai 700 unit akad kredit dalam 2 tahun pertama launching.'
            },
            {
                number: 90,
                postFix: '%',
                title: 'Tingkat Hunian',
                description: 'Perumahan yang berdampingan dengan hutan penelitian menjadikan area Kebun Kembang Asri sangat sejuk dan nyaman untuk dihuni. Tingkat hunian yang tinggi mencapai 90% dengan jumlah 630 KK dalam 2 tahun pertama launching.'
            }
        ]
    },
    unitTypes: {
        isSlide: true,
        slide1: [
            {
                img: Kka23672Img,
                unit: 'KKA 2 36/72',
                route: '/project/kka-2/type-36-72',
            },
            {
                img: Kka23060Img,
                unit: 'KKA 2 30/60',
                route: '/project/kka-2/type-30-60',
            },
            {
                img: Kka23056Img,
                unit: 'KKA 2 30/56',
                route: '/project/kka-2/type-30-56',
            },
        ],
        slide2: [
            {
                img: Kka2e3672Img,
                unit: 'KKA 2 36/72 Blok E',
                route: '/project/kka-2/type-36-72-e',
            },
            {
                img: Kka2f3672Img,
                unit: 'KKA 2 36/72 Blok F',
                route: '/project/kka-2/type-36-72-f',
            },
            {
                img: Kka23072Img,
                unit: 'KKA 2 30/72',
                route: '/project/kka-2/type-30-72',
            },
        ],
    },
    planImg: PlanImg,
    planImgMobile: PlanImgMobile,
    slideProjects: [
        {
            img: slideProject1,
            altText: 'e1-construction1'
        },
        {
            img: slideProject2,
            altText: 'e1-construction2'
        },
        {
            img: slideProject3,
            altText: 'e1-construction3'
        },
        {
            img: slideProject4,
            altText: 'e1-construction4'
        },
        {
            img: slideProject5,
            altText: 'e1-construction5'
        },
    ]
}