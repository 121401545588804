import React from 'react'
import Logo from '../../assets/images/Logo-white.png'
import { FaFacebookF, FaInstagram, FaYoutube, FaTiktok } from 'react-icons/fa'
import './Footer.css'

const Footer = () => {
  return (
    <div className='footer'>
      <div className="upper-footer">
        <div className='footer-section'>
          <img src={Logo} alt='praja' width={239} height={195} />
        </div>
        <div className='footer-section'>
          <h4>Contact</h4>
          <p>
            <span>
              {/* <strong>PH :</strong> 0264 8303945
              <br /> */}
              <strong>WA :</strong> 0812 9011 1136
              <br />
              <strong>E :</strong> info@prajasaktigroup.com
            </span>
          </p>
          <div className='socmed'>
            <a className='socmed-link' href="https://www.facebook.com/perumahankebunkembangasri/" target="_blank" rel="noreferrer">
              <FaFacebookF />
            </a>
            <a className='socmed-link' href="https://www.instagram.com/perumahankebunkembangasri" target="_blank" rel="noreferrer">
              <FaInstagram />
            </a>
            <a className='socmed-link' href="https://www.youtube.com/channel/UCFLX5HWc4DlY0Z71srJpd0g" target="_blank" rel="noreferrer">
              <FaYoutube />
            </a>
            <a className='socmed-link' href="https://www.tiktok.com/@kka_official?_t=8WHmtQDn2HJ&_r=1" target="_blank" rel="noreferrer">
              <FaTiktok />
            </a>
          </div>
        </div>
        <div className='footer-section'>
          <h4>Head Office</h4>
          <p>
            <span>Jl Palmerah Barat No. 45 B-C Kelurahan Grogol Utara, Kecamatan Kebayoran Lama
              Jakarta Selatan</span>
          </p>
          <br />
          <p className='mb-15'>
            <b>Hours</b>
            <br />
            <span>Senin - Jumat : 08.30 - 17.30 WIB</span>
          </p>
        </div>
        <div className='footer-section'>
          <h4>Marketing Office</h4>
          <p>
            <span>Perumahan Kebun Kembang Asri Blok E, Desa Sarimulya, Kecamatan Kotabaru, Kabupaten Karawang,
              <br />Jawa Barat</span>
          </p>
          <br />
          <p className='mb-15'>
            <b>Hours</b>
            <br />
            <span>Senin - Jumat : 08.30 - 17.30 WIB
            <br />
            Sabtu &amp; Minggu : 08.30 - 14.30 WIB</span>
          </p>
        </div>
      </div>
        <div className='footer-copyright'>
          <span>Copyright &copy; 2021 Praja Sakti Propertindo.</span>
        </div>
    </div>
  )
}

export default Footer