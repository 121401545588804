import { useCallback, useRef, useState } from 'react'
import './Slider.css'
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io'
import { useEffect } from 'react'
import { BsRecord, BsRecordFill } from 'react-icons/bs'

const Slider = ({ slides, hasUnderline, useText = true, sliderStyle, leftButtonsStyle, rightButtonsStyle, slideNavStyle, useBullets, slideNavBtnStyle }) => {
  const sliderStyles = { ...sliderStyle }
  const leftButtonsStyles = { ...leftButtonsStyle }
  const rightButtonsStyles = { ...rightButtonsStyle }
  const slideNavStyles = { ...slideNavStyle }
  const slideNavBtnStyles = { ...slideNavBtnStyle }
  const [current, setCurrent] = useState(0)
  const [dataLength] = useState(slides && slides.length > 0 ? slides.length : 0)
  const timeoutRef = useRef(null)
  
  const changeSlide = useCallback((state) => {
    clearTimeout(timeoutRef.current) // Clear any previous timeout
    if (state === 'prev') {
      setCurrent(current - 1 < 0 ? dataLength : current - 1)
    } else {
      setCurrent(current + 1 === dataLength ? 0 : current + 1)
    }
    timeoutRef.current = setTimeout(() => {
      changeSlide('next') // Call changeSlide again after a delay
    }, 2500)
  }, [current, dataLength])

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      changeSlide('next')
    }, 2500)

    return () => clearTimeout(timeoutRef.current) // Clear the timeout on unmount
  }, [current, changeSlide])
  
  return (
    <div className='slider' style={sliderStyles}>
      <div className="slider-inner">
        {slides ? slides.map((item, index) => {
          return (
            <div id={index} key={index} className={`slider-item ${current === index ? 'active' : ''}`}>
              <img src={item.img} className="bg" alt={item.altText || item.text1} />
              {item.textBg ? <img src={item.textBg} alt="text" className="text-bg" /> : null}
              {
                useText ? (
                  <span className='text'>
                    <span className='text-1'>{item.text1}</span>
                    <br />
                    <span className='text-2'>{item.text2}</span>
                    {hasUnderline ? <div className='underline'></div> : null}
                  </span>
                ) : null
              }
            </div>
          )
        }) : null}
        {
          slides.length > 1 ? (
            <>
              <div style={leftButtonsStyles} className='slider-prev' onClick={() => changeSlide('prev')}><IoIosArrowDropleftCircle /></div>
              <div style={rightButtonsStyles} className='slider-next' onClick={() => changeSlide('next')}><IoIosArrowDroprightCircle /></div>
            </>
          ) : null
        }
        {
          useBullets ? (
            <div className='slider-nav' style={slideNavStyles}>
              {slides.map((item, index) => <button style={slideNavBtnStyles} key={index} onClick={() => setCurrent(index)}>{index === current ? <BsRecordFill /> : <BsRecord />}</button>)}
            </div>
          ) : null
        }
      </div>
    </div>
  )
}

export default Slider