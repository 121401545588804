import { useEffect } from 'react'
import ProjectLayout from '../../../../layout/ProjectLayout'
import { kkaRuko4345 } from '../../../../data/kka-Ruko4345'

export const Type4345 = () => {
  useEffect(() => {
    document.title = 'KEBUN KEMBANG ASRI - Type Ruko 43/45 - Website Praja'
  }, [])

  return (
    <ProjectLayout data={kkaRuko4345} />
  )
}
