import slideImg1 from '../assets/images/01.png'
import textBg from '../assets/images/Slider-Home17.png'
import LeaveBg from '../assets/images/leave-bg.jpg'
import Kka13672DescImg from '../assets/images/KKA-1-Tipe-Unit-36.72.png'
import Kka13672Img from '../assets/images/01.png'
import Ruko3941DescImg from '../assets/images/KKA-2-Ruko-Tipe-39.41-desc.png'
import Ruko3941Img from '../assets/images/view 4.jpg'
import PlanImg from '../assets/images/ruko-43.45.png'
import PlanImgMobile from '../assets/images/ruko-43.45-mobile.png'

export const kkaRuko4345 = {
    slides: [
        {
            img: slideImg1,
            text1: 'Tersedia Unit Ready Stok',
            textBg: textBg
        },
    ],
    achievementSection: {
        title: 'Project Achievement',
        sectionBg: LeaveBg,
        achievements: [
            {
                number: 98,
                postFix: '%',
                title: 'Sold',
                description: 'Rumah impian yang banyak diminati karena letaknya yang strategis dan harga yang terjangkau.'
            },
            {
                number: 700,
                title: 'Akad dalam 2 Tahun',
                description: 'Tercapai 700 unit akad kredit dalam 2 tahun pertama launching.'
            },
            {
                number: 90,
                postFix: '%',
                title: 'Tingkat Hunian',
                description: 'Perumahan yang berdampingan dengan hutan penelitian menjadikan area Kebun Kembang Asri sangat sejuk dan nyaman untuk dihuni. Tingkat hunian yang tinggi mencapai 90% dengan jumlah 630 KK dalam 2 tahun pertama launching.'
            }
        ]
    },
    // unitTypes: [
    //     {
    //         img: Kka13672Img,
    //         descImg: Kka13672DescImg,
    //         unit: 'KKA 1 36/72',
    //         route: '/project/kka-1/type-36-72',
    //     },
    //     {
    //         img: Ruko3941Img,
    //         descImg: Ruko3941DescImg,
    //         unit: 'Ruko/Commercial Type 39/41',
    //         route: '/project/ruko-commercial/type-39-41',
    //     }
    // ],
    planImg: PlanImg,
    planImgMobile: PlanImgMobile,
}