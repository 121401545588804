import { useEffect } from 'react'
import MainLayout from '../../layout/MainLayout'
import { contact } from '../../data/contact'

export const ContactUs = () => {
  useEffect(() => {
    document.title = 'Contact Us - Website Praja'
  }, [])
  return (
    <MainLayout pageTitle={'Contact Us'} data={contact} />
  )
}
