import { useEffect } from 'react'
import EventLayout from '../../layout/EventLayout'
import { events } from '../../data/events'

export const EventInvitation = () => {
  useEffect(() => {
    document.title = 'Event - Website Praja'
  }, [])
  return (
    <EventLayout data={events} />
  )
}
