// import { useState } from 'react'
import './DirectorGalleryContent.css'
import Slider from 'react-slick'
const DirectorGalleryContent = ({ data }) => {
    // const [slider1, setSlider1] = useState(null)
    // const [slider2, setSlider2] = useState(null)

    // const settings1 = {
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     arrows: false,
    //     fade: true,
    // }

    const settings2 = {
        className: 'center',
        dots: true,
        infinite: true,
        speed: 2500,
        autoplaySpeed: 2500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        centerMode: true,
        centerPadding: '60px',
        responsive: [
            {
                breakpoint: 512,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                    centerPadding: 0,
                }
            }
        ]
    }

    return (
        <div className='gallery-section'>
            <h2>{data.title}</h2>
            <div className="directors">
                {/* <Slider asNavFor={slider2} ref={slider => setSlider1(slider)} {...settings1}>
                    {data.images.map((item, key) => {
                        return (
                            <div key={key}>
                                <img className='slide-bg' src={item} alt={`director_${key + 1}`} />
                            </div>
                        )
                    })}
                </Slider> */}
                <Slider 
                    // asNavFor={slider1} ref={slider => setSlider2(slider)} 
                    {...settings2}>
                    {data.images.map((item, key) => {
                        return (
                            <div key={key}>
                                <img className='slide-img' src={item} alt={`director_${key + 1}`} />
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </div>
    )
}

export default DirectorGalleryContent