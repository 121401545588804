import React from 'react'
import './EventsList.css'

const EventsList = ({ data }) => {
    // const result = data.reduce((acc, curr) => {
    //     const date = new Date(curr.dateCreated);
    //     const year = date.getFullYear();
    //     const month = date.toLocaleString('default', { month: 'long' });

    //     const key = `${month} ${year}`;

    //     if (!acc[key]) {
    //         acc[key] = [];
    //     }

    //     acc[key].push(curr);

    //     return acc;
    // }, {});

  return (
    <div className='events-list-container'>
        <div className="events-section">
              {data ? data.map((item, index) => {
                const inputDate = item.dateCreated
                const date = new Date(inputDate)
                const options = { month: 'long', day: 'numeric', year: 'numeric' };
                const formattedDate = date.toLocaleDateString('en-US', options);

                return (
                    <div className='event' key={index}>
                        <span>{formattedDate}</span>
                        <div className='event-content'>
                            <div className="event-img">
                                <img src={item.thumbnail} alt={item.title} />
                            </div>
                            <div className="event-text">
                                <h3 className="event-title">{item.title}</h3>
                                <p className="event-text">{item.text}</p>
                                <a href={item.link} target="_blank" rel="noreferrer">Selengkapnya</a>
                            </div>
                        </div>
                    </div>
                  )
              }) : null}
        </div>
        {/* <div className="archive-section">
            <h2>Archives</h2>
        </div> */}
    </div>
  )
}

export default EventsList