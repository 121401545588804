import { useEffect } from 'react'
import ProjectLayout from '../../../../layout/ProjectLayout'
import { kka23672e } from '../../../../data/kka-23672e'

export const Type3672e = () => {
  useEffect(() => {
    document.title = 'KEBUN KEMBANG ASRI 2 - Type 36/72 - Website Praja'
  }, [])

  return (
    <ProjectLayout data={kka23672e} />
  )
}
