import React from 'react'
import EventsList from '../components/EventsList/EventsList'
import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'
import ScrollToTop from '../components/ScrollToTop/ScrollToTop'
import WhatsAppBtn from '../components/WhatsAppBtn/WhatsAppBtn'

const EventLayout = ({ data }) => {
  return (
    <div style={{ maxWidth: '100vw', overflow: 'hidden' }}>
        <Navbar />
        {data.events ? <EventsList data={data.events} /> : null}
        <Footer />
        <WhatsAppBtn />
        <ScrollToTop />
    </div>
  )
}

export default EventLayout