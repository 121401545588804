import './Navbar.css'
import React, { useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
// import { useScrollHandler } from '../../hooks/UseScrollHandler'
import { GiHamburgerMenu } from 'react-icons/gi'
import { CgClose } from 'react-icons/cg'
import { FiChevronUp, FiChevronDown } from 'react-icons/fi'

const Navbar = () => {
    // const scroll = useScrollHandler()
    // const [sidebar, setSidebar] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [isProjectOpen, setIsProjectOpen] = useState(false)
    const [isKka2Open, setIsKka2Open] = useState(false)
    const [isMobileView, setIsMobileView] = useState(false)

    let location = useLocation()

    useEffect(() => {
        if (window.innerWidth > 768) {
            setIsMobileView(false)
        } else {
            setIsMobileView(true)
        }
    }, [])
    
    const toggleNav = () => {
        setIsOpen(!isOpen)
    }
  return (
    <nav className={isMobileView ? '' : 'menu'} style={{ height: isOpen ? '100vh' : '10vh', transition: 'height .5s ease' }}>
        <div className='logo-and-toggler'>
            <div className="logo">
                <NavLink to={'/'}><img src={process.env.PUBLIC_URL + '/assets/images/Logo-Ganti-Baru.png'} alt="praja" /></NavLink>
            </div>
            {isMobileView ? (
                <div className="nav-toggler" onClick={toggleNav}>
                    {isOpen ? <CgClose /> : <GiHamburgerMenu />}
                </div>
            ) : null}
        </div>
        {
            isMobileView ? (
                <ul style={{ position: 'absolute', top: '80px', left: isOpen ? '0' : '-500px', transition: 'left .5s ease' }}>
                    <li><NavLink to={'/'}>Home</NavLink></li>
                    <li><NavLink to={'/about'}>About</NavLink></li>
                    <li>
                        <span className='link-toggle' onClick={() => setIsProjectOpen(!isProjectOpen)}>Project {isProjectOpen ? <FiChevronUp className='btn' /> : <FiChevronDown className='btn' />}</span>
                        <ul className={isProjectOpen ? 'projects-open' : 'projects'}>
                            <li><NavLink to={'/project/kka-1/type-45-84'}>Kebun Kembang Asri 1</NavLink></li>
                            <li>
                                <span className='link-toggle' onClick={() => setIsKka2Open(!isKka2Open)}>Kebun Kembang Asri 2 {isKka2Open ? <FiChevronUp className='btn' /> : <FiChevronDown className='btn' />}</span>
                                <ul className={isKka2Open ? 'projects-open' : 'projects'}>
                                    <li><NavLink to={'/project/kka-2/type-subsidi-27-60'}>Subsidi</NavLink></li>
                                    <li><NavLink to={'/project/kka-2/type-30-56'}>Non Subsidi</NavLink></li>
                                    <li><NavLink to={'/project/ruko-commercial/type-39-41'}>Ruko / Commercial Area</NavLink></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><NavLink to={'/event-invitation'}>Event</NavLink></li>
                    <li><NavLink to={'/partnership'}>Partnership</NavLink></li>
                    <li><NavLink to={'/contact'}>Contact Us</NavLink></li>
                </ul>
            ) : (
                <ul className='menu-links'>
                    <li className={!isMobileView && location.pathname === '/' ? 'active-link' : ''}><NavLink to={'/'}>Home</NavLink></li>
                    <li className={!isMobileView && location.pathname === '/about' ? 'active-link' : ''}><NavLink to={'/about'}>About</NavLink></li>
                    <li className={!isMobileView && location.pathname.includes('project') ? 'active-link' : ''}>
                        <span>Project {isProjectOpen ? <FiChevronUp className='btn' /> : <FiChevronDown className='btn' />}</span>
                        <ul>
                            <li><NavLink to={'/project/kka-1/type-45-84'}>Kebun Kembang Asri 1</NavLink></li>
                            <li>
                                <span>Kebun Kembang Asri 2 {isKka2Open ? <FiChevronUp className='btn' /> : <FiChevronDown className='btn' />}</span>
                                <ul className='child-menu'>
                                    <li><NavLink to={'/project/kka-2/type-subsidi-27-60'}>Subsidi</NavLink></li>
                                    <li><NavLink to={'/project/kka-2/type-30-56'}>Non Subsidi</NavLink></li>
                                    <li><NavLink to={'/project/ruko-commercial/type-39-41'}>Ruko / Commercial Area</NavLink></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className={!isMobileView && location.pathname === '/event-invitation' ? 'active-link' : ''}><NavLink to={'/event-invitation'}>Event</NavLink></li>
                    <li className={!isMobileView && location.pathname === '/partnership' ? 'active-link' : ''}><NavLink to={'/partnership'}>Partnership</NavLink></li>
                    <li className={!isMobileView && location.pathname === '/contact' ? 'active-link' : ''}><NavLink to={'/contact'}>Contact Us</NavLink></li>
                </ul>
            )
        }
    </nav>
    // <header className={scroll ? 'header' : 'header-sticky'}>
    //     <div className='navbar-container'>
    //         <div className={scroll ? 'logo' : 'logo-sticky'}>
    //             <NavLink to={'/'}><img src="assets/images/Logo-Ganti-Baru.png" alt="Logo" /></NavLink>
    //         </div>
    //         <div className={scroll ? 'nav' : 'nav-sticky'}>
    //             <ul className={sidebar ? 'nav-links-sidebar' : 'nav-links'} onClick={() => setSidebar(false)}>
    //                 <li><NavLink to={'/'} className={(state) => state.isActive ? 'active-nav-link' : 'nav-link'}><span>Home</span></NavLink></li>
    //                 <li><NavLink to={'/about'} className={(state) => state.isActive ? 'active-nav-link' : 'nav-link'}><span>About</span></NavLink></li>
    //                 <li id='parent'>
    //                     Project
    //                     <ul>
    //                         <li><NavLink to={'/project/kka-1/type-45-84'} className={(state) => state.isActive ? 'active-nav-link' : 'nav-link'}>Kebun Kembang Asri 1</NavLink></li>
    //                         <li>
    //                             Kebun Kembang Asri 2
    //                             <ul>
    //                                 <li>Subsidi</li>
    //                                 <li>Non Subsidi</li>
    //                                 <li>Ruko / Commercial Area</li>
    //                             </ul>
    //                         </li>
    //                     </ul>
    //                 </li>
    //                 <li><NavLink to={'/event-invitation'} className={(state) => state.isActive ? 'active-nav-link' : 'nav-link'}><span>Event</span></NavLink></li>
    //                 <li><NavLink to={'/partnership'} className={(state) => state.isActive ? 'active-nav-link' : 'nav-link'}><span>Partnership</span></NavLink></li>
    //                 <li><NavLink to={'/contact'} className={(state) => state.isActive ? 'active-nav-link' : 'nav-link'}><span>Contact Us</span></NavLink></li>
    //             </ul>
    //         </div>
    //         <button className='navbar-items-icon' onClick={() => setSidebar(!sidebar)}>
    //             {sidebar ? 'X' : '='}
    //         </button>
    //     </div>
    // </header>
  )
}

export default Navbar