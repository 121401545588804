import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Homepage } from './pages/Home'
import { About } from './pages/About'
import { Partnership } from './pages/Partnership'
import { Type3672 } from './pages/Project/KKA-1/Type-36-72'
import { Type4584 } from './pages/Project/KKA-1/Type-45-84'
import { Type3056 } from './pages/Project/KKA-2/Type-30-56'
import { Type3060 } from './pages/Project/KKA-2/Type-30-60'
import { Type3072 } from './pages/Project/KKA-2/Type-30-72'
import { Kka2Type3672 } from './pages/Project/KKA-2/Type-36-72'
import { Type3672e } from './pages/Project/KKA-2/Type-36-72-e'
import { Type3672f } from './pages/Project/KKA-2/Type-36-72-f/Type-36-72-f';
import { TypeSubsidi2760 } from './pages/Project/KKA-2/Type-Subsidi-27-60'
import { Type3941 } from './pages/Project/Ruko/Type-39-41'
import { Type4345 } from './pages/Project/Ruko/Type-43-45'

import { EventInvitation } from './pages/EventInvitation'
import { ContactUs } from './pages/ContactUs'

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='about' element={<About />} />
          <Route path='project'>
            <Route path='kka-1'>
              <Route path='type-36-72' element={<Type3672 />} />
              <Route path='type-45-84' element={<Type4584 />} />
            </Route>
            <Route path='kka-2'>
              <Route path='type-30-56' element={<Type3056 />} />
              <Route path='type-30-60' element={<Type3060 />} />
              <Route path='type-30-72' element={<Type3072 />} />
              <Route path='type-36-72' element={<Kka2Type3672 />} />
              <Route path='type-36-72-e' element={<Type3672e />} />
              <Route path='type-36-72-f' element={<Type3672f />} />
              <Route path='type-subsidi-27-60' element={<TypeSubsidi2760 />} />
            </Route>
            <Route path='ruko-commercial'>
              <Route path='type-39-41' element={<Type3941 />} />
              <Route path='type-43-45' element={<Type4345 />} />
            </Route>
          </Route>
          <Route path='event-invitation' element={<EventInvitation />} />
          <Route path='partnership' element={<Partnership />} />
          <Route path='contact' element={<ContactUs />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
