import { useEffect } from 'react'
import ProjectLayout from '../../../../layout/ProjectLayout'
import { kka23060 } from '../../../../data/kka-23060'

export const Type3060 = () => {
  useEffect(() => {
    document.title = 'KEBUN KEMBANG ASRI 2 - Type 30/60 - Website Praja'
  }, [])

  return (
    <ProjectLayout data={kka23060} />
  )
}
