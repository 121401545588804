import React from 'react'
import './ContactContent.css'
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaComments } from 'react-icons/fa'

const ContactContent = () => {
  return (
    <div className='contact-content'>
        <div className="contact-content-items">
            <div className="contact-content-item">
                <div className="contact-content-item-left">
                    <div className="contact-content-logo">
                        <FaMapMarkerAlt />
                    </div>
                </div>
                <div className="contact-content-item-right">
                    <div className="contact-content-description">
                        <h5>Head Office</h5>
                        <p>Jln Palmerah Barat No. 45 B-C, Kelurahan Grogol Utara, Kecamatan Kebayoran Lama, Jakarta Selatan</p>
                        <p><b>Hours</b></p>
                        <p>Senin &#8211; Jumat : 08.30 &#8211; 17.30 WIB</p>
                    </div>
                </div>
            </div>
            <div className="contact-content-item">
                <div className="contact-content-item-left">
                    <div className="contact-content-logo">
                        <FaMapMarkerAlt />
                    </div>
                </div>
                <div className="contact-content-item-right">
                    <div className="contact-content-description">
                        <h5>Marketing Office</h5>
                        <p>Perumahan Kebun Kembang Asri Blok E, Desa Sarimulya, Kecamatan Kotabaru, Kabupaten Karawang, Jawa Barat</p>
                        <p><b>Hours</b></p>
                        <p>Senin &#8211; Jumat : 08.30 &#8211; 17.30 WIB</p>
                        <p>Sabtu &amp; Minggu : 07.30 &#8211; 14.30 WIB</p>
                    </div>
                </div>
            </div>
            <div className="contact-content-item">
                <div className="contact-content-item-left">
                    <div className="contact-content-logo">
                        <FaEnvelope />
                    </div>
                </div>
                <div className="contact-content-item-right">
                    <div className="contact-content-description">
                        <p>info@prajasaktigroup.com</p>
                    </div>
                </div>
            </div>
            <div className="contact-content-item">
                <div className="contact-content-item-left">
                    <div className="contact-content-logo">
                        <FaPhone />
                    </div>
                </div>
                <div className="contact-content-item-right">
                    <div className="contact-content-description">
                        <p>0264 8303945</p>
                    </div>
                </div>
            </div>
            <div className="contact-content-item">
                <div className="contact-content-item-left">
                    <div className="contact-content-logo">
                        <FaComments />
                    </div>
                </div>
                <div className="contact-content-item-right">
                    <div className="contact-content-description">
                        <p>0812-9011-1136</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ContactContent