import { useEffect, useState } from 'react'
import { useScrollHandler } from '../../hooks/UseScrollHandler'
import './ProjectAchievement.css'
// import LeaveImg from '../../assets/images/leave.png'

const ProjectAchievement = ({ data }) => {
  const scroll = useScrollHandler(false, 200)
  const [achievements, setAchievements] = useState([])

  useEffect(() => {
    if (data.achievements) {
      const newAchievements = data.achievements.map((item) => {
        return {
          ...item,
          animatedNumber: 0,
        };
      });
      setAchievements(newAchievements);
    }
  }, [data])

  useEffect(() => {
    if (scroll) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const itemIndex = Number(entry.target.dataset.index);
            const item = achievements[itemIndex];
            const animateNumber = () => {
              if (item.animatedNumber < item.number) {
                const newAchievements = [...achievements];
                newAchievements[itemIndex].animatedNumber++;
                setAchievements(newAchievements);
                setTimeout(animateNumber, 5); // adjust delay time here
              }
            };
            setTimeout(animateNumber, 150); // adjust delay time here
          }
        });
      });

      const items = document.querySelectorAll('.achievement');
      items.forEach((item, index) => {
        item.dataset.index = index;
        observer.observe(item);
      });

      return () => {
        observer.disconnect();
      };      
    }
  }, [achievements, scroll]);

  return (
    <div className='achievement-section' style={{ backgroundImage: `url(${data.sectionBg})` }}>
      {/* <img src={LeaveImg} className='leave' alt="leave" /> */}
      <h2>{data.title}</h2>
      <div className="achievements">
        {achievements.map((item, index) => {
          return (
            <div className='achievement' key={index}>
              <div className="number">
                <span>{item.animatedNumber}</span>
                <span className="post-fix">{item.postFix}</span>
              </div>
              <h3>{item.title}</h3>
              <hr />
              <div className="description">
                {item.description}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ProjectAchievement