import { useEffect } from 'react'
import MainLayout from '../../layout/MainLayout'
import { about } from '../../data/about'

export const About = () => {
  useEffect(() => {
    document.title = 'About - Website Praja'
  }, [])

  return (
    <MainLayout pageTitle={'About'} data={about} />
  )
}
