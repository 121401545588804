import { useEffect } from 'react'
import MainLayout from '../../layout/MainLayout'
import { partnership } from '../../data/partnership'

export const Partnership = () => {
    useEffect(() => {
        document.title = 'Partnership - Website Praja'
    }, [])

    return (
        <MainLayout pageTitle={'Partnership'} data={partnership} />
    )
}
