import { useEffect } from 'react'
import ProjectLayout from '../../../../layout/ProjectLayout'
import { kkaRuko3941 } from '../../../../data/kka-Ruko3941'

export const Type3941 = () => {
  useEffect(() => {
    document.title = 'KEBUN KEMBANG ASRI - Type Ruko 39/41 - Website Praja'
  }, [])

  return (
    <ProjectLayout data={kkaRuko3941} />
  )
}
