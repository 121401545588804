import { useEffect } from 'react'
import ProjectLayout from '../../../../layout/ProjectLayout'
import { kka23672f } from '../../../../data/kka-23672f'

export const Type3672f = () => {
  useEffect(() => {
    document.title = 'KEBUN KEMBANG ASRI 2 - Type 36/72 - Website Praja'
  }, [])
  return (
    <ProjectLayout data={kka23672f} />
  )
}
