import { useEffect } from 'react'
import ProjectLayout from '../../../../layout/ProjectLayout'
import { kka23072 } from '../../../../data/kka-23072'

export const Type3072 = () => {
  useEffect(() => {
    document.title = 'KEBUN KEMBANG ASRI 2 - Type 30/72 - Website Praja'
  }, [])

  return (
    <ProjectLayout data={kka23072} />
  )
}
