import React from 'react'
import './SectionContent.css'

const SectionContent = ({ data }) => {
  return (
    <div className='section'>
      <div className="section-title"><h2>{data.title}</h2></div>
      <div className="section-content">
        <div className="content-img">
          <img src={data.img} alt="fasad" />
        </div>
        <div className="content-text">
          {
            data.text.map((text, index) => <div key={index} className="section-text"><p>{text}</p></div>)
          }
        </div>
      </div>
    </div>
  )
}

export default SectionContent