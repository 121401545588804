import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import Slider from '../components/Slider/Slider'
import WhatsAppBtn from '../components/WhatsAppBtn/WhatsAppBtn'
import ScrollToTop from '../components/ScrollToTop/ScrollToTop'
import ProjectLocation from '../components/ProjectLocation/ProjectLocation'
import RadiusMap from '../components/RadiusMap/RadiusMap'
import ProjectAchievement from '../components/ProjectAchievement/ProjectAchievement'
import ProjectAdvantages from '../components/ProjectAdvantages/ProjectAdvantages'
import Facilitites from '../components/Facilities/Facilitites'
import UnitType from '../components/UnitType/UnitType'
// import ProjectConcept from '../components/ProjectConcept/ProjectConcept'
import FormContent from '../components/FormContent/FormContent'

const ProjectLayout = ({ data }) => {
  return (
    <div style={{ maxWidth: '100vw', overflow: 'hidden' }}>
        <Navbar />
        {data.slides ? <Slider hasUnderline slides={data.slides} /> : null}
        { data.achievementSection ? <ProjectAchievement data={data.achievementSection} /> : null }
        {/* <ProjectConcept /> */}
        <RadiusMap />
        <ProjectAdvantages />
        <Facilitites />
        <ProjectLocation />
        {data.unitTypes ? <UnitType data={data.unitTypes} planImg={data.planImg} planImgMobile={data.planImgMobile} slides={data.slideProjects} /> : null}
        {data.formContent ? <FormContent data={data.formContent} /> : null}
        <Footer />
        <WhatsAppBtn />
        <ScrollToTop />
    </div>
  )
}

export default ProjectLayout