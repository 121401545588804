import { useState, useEffect } from "react"

export const useScrollHandler = (onlyPc, yBenchmark) => {
    const [scroll, setScroll] = useState(1)

    useEffect(() => {
        const onScroll = () => {
            if (onlyPc) {
                if (window.outerWidth > 768) {
                    const scrollCheck = window.scrollY < yBenchmark
                    if (scrollCheck !== scroll) {
                        setScroll(scrollCheck)
                    }
                }                
            } else {
                const scrollCheck = window.scrollY < yBenchmark
                if (scrollCheck !== scroll) {
                    setScroll(scrollCheck)
                }
            }
        }

        document.addEventListener('scroll', onScroll)
        return () => {
            document.removeEventListener('scroll', onScroll)
        }
    }, [scroll, setScroll, onlyPc, yBenchmark])

    return scroll
}