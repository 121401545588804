import slideImg1 from '../assets/images/KKA-2-Subs - 1. Rumah Subsidi dengan Double dinding dan Plafon Gypsum Pertama di Cikampek.jpg'
import slideImg2 from '../assets/images/KKA-2-Subs - 2. Rumah Subsidi dengan Double dinding dan Plafon Gypsum Pertama di Cikampek.jpg'
import slideImg3 from '../assets/images/KKA-2-Subs - 3. Ready Stok dan Siap Huni.jpg'
import slideImg4 from '../assets/images/KKA-2-Subs - 4. Ready Stok dan Siap Huni.jpg'
import textBg from '../assets/images/Slider-Home17.png'
import LeaveBg from '../assets/images/leave-bg.jpg'
import KkaSubs22760Img from '../assets/images/Type Subsidi 27-60.jpg'
import PlanImg from '../assets/images/27.60-2.png'
import PlanImgMobile from '../assets/images/27.60-2-mobile.png'
import slideProject1 from '../assets/images/KKA-2-Subs - 1. Rumah Subsidi dengan Double dinding dan Plafon Gypsum Pertama di Cikampek.jpg'
import slideProject2 from '../assets/images/KKA-2-Subs - 2. Rumah Subsidi dengan Double dinding dan Plafon Gypsum Pertama di Cikampek.jpg'
import slideProject3 from '../assets/images/KKA-2-Subs - 3. Ready Stok dan Siap Huni.jpg'
import slideProject4 from '../assets/images/KKA-2-Subs - 4. Ready Stok dan Siap Huni.jpg'
import slideProject5 from '../assets/images/KKA-2-Subs - Gambar 3D Subsidi 27,60.jpg'

export const kkaSubsidi2760 = {
    slides: [
        {
            img: slideImg1,
            text1: 'Rumah Subsidi dengan Double dinding dan Plafon Gypsum',
            text2: 'Pertama di Cikampek',
            textBg: textBg
        },
        {
            img: slideImg2,
            text1: 'Rumah Subsidi dengan Double dinding dan Plafon Gypsum',
            text2: 'Pertama di Cikampek',
            textBg: textBg
        },
        {
            img: slideImg3,
            text1: 'Ready Stok dan Siap Huni',
            textBg: textBg
        },
        {
            img: slideImg4,
            text1: 'Ready Stok dan Siap Huni',
            textBg: textBg
        },
    ],
    achievementSection: {
        title: 'Project Achievement',
        sectionBg: LeaveBg,
        achievements: [
            {
                number: 98,
                postFix: '%',
                title: 'Sold',
                description: 'Rumah impian yang banyak diminati karena letaknya yang strategis dan harga yang terjangkau.'
            },
            {
                number: 700,
                title: 'Akad dalam 2 Tahun',
                description: 'Tercapai 700 unit akad kredit dalam 2 tahun pertama launching.'
            },
            {
                number: 90,
                postFix: '%',
                title: 'Tingkat Hunian',
                description: 'Perumahan yang berdampingan dengan hutan penelitian menjadikan area Kebun Kembang Asri sangat sejuk dan nyaman untuk dihuni. Tingkat hunian yang tinggi mencapai 90% dengan jumlah 630 KK dalam 2 tahun pertama launching.'
            }
        ]
    },
    unitTypes: [
        {
            img: KkaSubs22760Img,
            unit: 'KKA 2 27/60',
            route: '/project/kka-2/type-subsidi-27-60',
        },
    ],
    planImg: PlanImg,
    planImgMobile: PlanImgMobile,
    slideProjects: [
        {
            img: slideProject1,
            altText: 'e1-construction1'
        },
        {
            img: slideProject2,
            altText: 'e1-construction2'
        },
        {
            img: slideProject3,
            altText: 'e1-construction3'
        },
        {
            img: slideProject4,
            altText: 'e1-construction4'
        },
        {
            img: slideProject5,
            altText: 'e1-construction5'
        },
    ]
}