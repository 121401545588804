import React from 'react'
import './PartnersContent.css'

const PartnersContent = ({ data }) => {
  return (
    <div className='partners-content'>
        <h2>{data.title}</h2>
        <h4>{data.subtitle}</h4>
        <div className="images">
          { data.partners.map((item, index) => 
            <div key={index} className='image'>
              <img src={item} key={index} alt={index} />
            </div>
          )}
        </div>
    </div>
  )
}

export default PartnersContent