import { useEffect } from 'react'
import ProjectLayout from '../../../../layout/ProjectLayout'
import { kka1 } from '../../../../data/kka-1'

export const Type4584 = () => {
  useEffect(() => {
    document.title = 'KEBUN KEMBANG ASRI 1 - Type 45/84 - Website Praja'
  }, [])

  return (
    <ProjectLayout data={kka1} />
  )
}
