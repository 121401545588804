import { FaChevronUp } from 'react-icons/fa'
import { useScrollHandler } from '../../hooks/UseScrollHandler'
import './ScrollToTop.css'

const ScrollToTop = () => {
    const scroll = useScrollHandler(true, 100)

    const handleScroll = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return (
        <div>
            <div className={`scroll-btn ${scroll ? 'hide' : ''}`} onClick={handleScroll} >
                <FaChevronUp />
            </div>
        </div>
    ) 
}

export default ScrollToTop