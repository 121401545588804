import React from 'react'
import './BodImagesSection.css'

const BodImagesSection = ({ data }) => {
  return (
    <div className='bod-section'>
      <div className="bod-section-left">
        <img src={data.image1} alt="bod" />
      </div>
      <div className="bod-section-middle">
        <h2>Get To Know Our Goals</h2>
        <p>Dalam meningkatkan perkembangan bisnis yang
          dijalankan, <br /> kami memiliki tujuan dan strategi yang tepat untuk
          mewujudkannya</p>
      </div>
      <div className="bod-section-right">
        <img src={data.image2} alt="bod" />
      </div>
    </div>
  )
}

export default BodImagesSection