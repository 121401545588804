import React from 'react'
import './VideoContent.css'

const VideoContent = ({ data }) => {
  return (
    <div className='video-content'>
      <h4>{data.title}</h4>
      <h2>{data.subtitle}</h2>
      <p>{data.text}</p>
      <div className='video'>
        <iframe 
          className='video-player'
          src={data.video} 
          allowFullScreen 
          width={'100%'} 
          height='400' 
          title={data.text}
        />
      </div>
    </div>
  )
}

export default VideoContent