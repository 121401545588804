import slideImg1 from '../assets/images/contact.jpg'
import textBg from '../assets/images/Slider-Home17.png'
import CsImg from '../assets/images/customer-service.jpg'

export const contact = {
    slides: [
        {
            img: slideImg1,
            text1: 'Get In Touch With',
            text2: 'Praja Sakti Propertindo',
            textBg: textBg
        },
    ],
    contactSection: true,
    formContent: {
        image: CsImg
    }
}