import React from 'react'
import './TypeContent.css'
import { NavLink } from 'react-router-dom'

const TypeContent = ({ data }) => {
  return (
    <div className='type-content'>
      <h2>{data.title}</h2>
      <div className='type-content-cards'>
        {data.types ? data.types.map((item, index) => {
          return (
            <div className='type-content-card' key={index}>
              <img src={item.img} alt={item.title} className='type-content-card-img' />
              <h5 className="type-content-card-title">{item.title}</h5>
              <div className="type-content-card-description">
                <p>{item.description}</p>
              </div>
            </div>
          )
        }) : null}
      </div>
      <NavLink className='redirect-btn' to={'/project/kka-2/type-subsidi-27-60'}>MORE PROJECTS</NavLink>
    </div>
  )
}

export default TypeContent