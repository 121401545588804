import slideImg1 from '../assets/images/1. Lokasi Strategis Akses Mudah di Tengah Kota Cikampek.jpg'
import slideImg2 from '../assets/images/2. Developer Berpengalaman dan Terpercaya.jpg'
import slideImg3 from '../assets/images/3. Project Terpadu 20 Ha.jpg'
import slideImg4 from '../assets/images/4. Dengan Total 1.500 Unit.jpg'
import slideImg5 from '../assets/images/5. Tersedia Berbagai Pilihan Type Unit.jpg'
import slideImg6 from '../assets/images/6. Tersedia Berbagai Pilihan Type Unit.jpg'
import slideImg7 from '../assets/images/7. Tersedia Berbagai Pilihan Type Unit.jpg'
import slideImg8 from '../assets/images/8. Tersedia Berbagai Pilihan Type Unit.jpg'
import textBg from '../assets/images/Slider-Home17.png'
import townHouseImg from '../assets/images/Town House.jpeg'
import subsidiImg from '../assets/images/Subsidi.jpg'
import nonSubsidiImg from '../assets/images/Non Subsidi.jpg'
import { TbHeartHandshake, TbShieldCheck } from 'react-icons/tb'
import { VscOrganization } from 'react-icons/vsc'
import CareerImg from '../assets/images/career-opportunities.jpg'
import CsImg from '../assets/images/customer-service.jpg'
import director1 from '../assets/images/HERI.jpg'
import director2 from '../assets/images/RIZAL.jpg'
import director3 from '../assets/images/NUGRAHA.jpg'
import director4 from '../assets/images/FIRMAN.jpg'
import director5 from '../assets/images/GRACE.jpg'

export const home = {
    slides: [
        {
            img: slideImg1,
            text1: 'Lokasi Strategis Akses Mudah',
            text2: 'di Tengah Kota Cikampek',
            textBg: textBg
        },
        {
            img: slideImg2,
            text1: 'Developer Berpengalaman dan',
            text2: 'Terpercaya',
            textBg: textBg
        },
        {
            img: slideImg3,
            text1: 'Project Terpadu 20 Ha',
            textBg: textBg
        },
        {
            img: slideImg4,
            text1: 'Dengan Total 1.500 Unit',
            textBg: textBg
        },
        {
            img: slideImg5,
            text1: 'Tersedia Berbagai Pilihan Type Unit',
            textBg: textBg
        },
        {
            img: slideImg6,
            text1: 'Tersedia Berbagai Pilihan Type Unit',
            textBg: textBg
        },
        {
            img: slideImg7,
            text1: 'Tersedia Berbagai Pilihan Type Unit',
            textBg: textBg
        },
        {
            img: slideImg8,
            text1: 'Tersedia Berbagai Pilihan Type Unit',
            textBg: textBg
        },
    ],
    cta: 'Ingin tahu lebih lanjut tentang perusahaan kami, silahkan mengunduh brosur kami.',
    typeContent: {
        title: 'Wujudkan Rumah Impian Anda Bersama Kebun Kembang Asri',
        types: [
            {
                img: townHouseImg,
                title: 'Type Town House',
                description: 'Tersedia Town House 1 Lantai dan 2 Lantai yang dapat Anda gunakan sebagai tempat usaha di lokasi strategis dengan cicilan yang ringan dan potensi market yang besar.'
            },
            {
                img: nonSubsidiImg,
                title: 'Type Non Subsidi',
                description: 'Tersedia berbagai tipe rumah yang berkualitas di Lokasi Strategis dengan harga yang terjangkau dan cicilan yang ringan.'
            },
            {
                img: subsidiImg,
                title: 'Type Subsidi',
                description: 'Tersedia unit Subsidi dengan double dinding dan plafon gypsum pertama di Cikampek dengan harga terjangkau.'
            }
        ]
    },
    directorsImages: {
        title: 'Meet Our Directors',
        images: [director1, director2, director3, director4, director5]
    },
    benefitContent: {
        title: 'Why Choose Us',
        subtitle: 'Ketahui berbagai keunggulan yang dimiliki.',
        text: 'Mengutamakan kepercayaan Konsumen, kami terus memberikan layanan yang terbaik dan penuh tanggap.',
        benefits: [
            {
                icon: <TbHeartHandshake />,
                title: 'Komitmen',
                description: 'Mengutamakan komitmen yang dimiliki untuk terus memberikan hunian terbaik, lokasi strategis, nyaman dan berkualitas dengan harga terjangkau yang kami hadirkan untuk memudahkan Anda dalam memenuhi kebutuhan.'
            },
            {
                icon: <VscOrganization />,
                title: 'Professional',
                description: 'Memiliki tenaga kerja yang ahli dan berpengalaman di bidangnya, kami terus berinovasi pada pengembangan properti yang berkesinambungan sehingga dapat mewujudkan hasil terbaik sesuai target yang telah ditentukan.'
            },
            {
                icon: <TbShieldCheck />,
                title: 'Integritas',
                description: 'Menjaga kepercayaan konsumen, vendor, seluruh karyawan, rekan usaha dan investor dengan mengedepankan tata kelola perusahaan yang baik dan sesuai bisnis proses yang GCG.'
            },
        ]
    },
    videoContent: {
        title: 'News & Event',
        subtitle: 'Ikuti terus info dan berita terbaru dari kami.',
        text: 'Kegiatan baik yang kami hadirkan untuk Anda pada waktu-waktu tertentu.',
        video: 'https://www.youtube.com/embed/Kf736TJD5x8'
    },
    careerContent: {
        image: CareerImg,
        title: 'Career Opportunities',
        subtitle: 'Bergabung Bersama PT. Praja Sakti Propertindo',
        text: 'Kirim email CV ke hrd@prajasaktigroup.com'
    },
    formContent: {
        image: CsImg
    }
}