import React from 'react'
import './ProjectLocation.css'

const ProjectLocation = () => {
  return (
    <div className='location-container'>
        <div className="location">
              <iframe 
                title='Location' 
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d75444.4295704019!2d107.46956649001365!3d-6.386336171084937!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x494d4906e05d0548!2sPerumahan%20Kebun%20Kembang%20Asri!5e0!3m2!1sid!2sid!4v1587094735835!5m2!1sid!2sid" 
                width="100%" 
                height="500" 
                frameborder="0" 
                style={{ border: 0 }} 
                allowfullscreen="" 
                aria-hidden="false" 
                tabindex="0"></iframe>
        </div>
        <div className="description">
            <h3>Kebun Kembang Asri</h3>
            <h1>Location</h1>
            <p>Terletak di tengah kota Cikampek, Kabupaten Karawang. <br />Jawa - Barat.</p>
        </div>
    </div>
  )
}

export default ProjectLocation