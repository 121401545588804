import React from 'react'
import './CareerContent.css'

const CareerContent = ({ data }) => {
  return (
    <div className='career-content'>
      <div className="career-left">
        <img src={data.image} alt={data.title} />
      </div>
      <div className="right">
        <h4>{data.title}</h4>
        <h2>{data.subtitle.substring(0, 17)} <br /> {data.subtitle.substring(18)}</h2>
        <p>{data.text}</p>
        <a href="mailto:hrd@prajasaktigroup.com" target='_blank' rel='noreferrer' className='career-button'>Join Now</a>
      </div>
    </div>
  )
}

export default CareerContent