import React from 'react'
import './BeOurPartnerContent.css'

const BeOurPartnerContent = ({ data }) => {
  return (
    <div className='be-partner-section'>
        <h2>{data.title}</h2>
        <h4>{data.subtitle}</h4>
        <div className="be-partner-cards">
            {data.partnerType.map((item, index) => {
                return (
                    <div key={index} className="be-partner-card">
                        <img src={item.image} alt={item.title} />
                        <h4>{item.title}</h4>
                        <hr />
                        <p>{item.description}</p>
                        <a href={`mailto:info@prajasaktigroup.com?subject=${item.title}`}>CONTACT NOW</a>
                    </div>
                )
            })}
        </div>
    </div>
  )
}

export default BeOurPartnerContent