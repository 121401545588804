import React from 'react'
import { FaWhatsapp } from 'react-icons/fa'
import './WhatsAppBtn.css'

const WhatsAppBtn = () => {
  return (
    <a 
      className='whatsapp-btn' 
      href='https://wa.me/6281290111136?text=Halo$20min!%20Saya%20mau%20tanya%20tentang%20proyek.' 
      target='_blank' 
      rel="noreferrer">
        <FaWhatsapp className='whatsapp-logo' />
    </a>
  )
}

export default WhatsAppBtn