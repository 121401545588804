import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import './UnitType.css'
import Slider from '../Slider/Slider'

const UnitType = ({ data, planImg, planImgMobile, slides }) => {
  const [showSlide1, setShowSlide1] = useState(true)
  const addedStyle = window.innerWidth > 768 ? { position: 'absolute', top: '2rem' } : { position: 'absolute', bottom: 0 }

  const renderUnitSlide = () => {
    if (showSlide1) {
      return data.slide1.map((item, index) => {
        return <NavLink className={`card fade-in-out ${showSlide1 ? 'visible' : ''}`} key={index} style={{ pointerEvents: window.location.href.includes(item.route) ? 'none' : 'auto' }} to={item.route}>
          <img src={item.img} alt={item.unit} />
        </NavLink>
      })      
    } else {
      return data.slide2.map((item, index) => {
        return <NavLink className='card' key={index} style={{ pointerEvents: window.location.href.includes(item.route) ? 'none' : 'auto' }} to={item.route}>
          <img src={item.img} alt={item.unit} />
        </NavLink>
      })
    }
  }

  useEffect(() => {
    const changeSlide = setInterval(() => {
      setShowSlide1(!showSlide1)
    }, 3000)
    return () => clearInterval(changeSlide)
  }, [showSlide1])
  return (
    <div>
      <div className="unit-type">
        <div className="title">
          <h2>Tipe Unit</h2>
          <span>Menghadirkan kawasan hunian dengan berbagai pilihan tipe unit yang bisa Anda pilih dan sesuaikan.</span>
        </div>
        <div className="cards">
          {
            !data.isSlide ? data.map((item, index) => {
              return <NavLink className='card' key={index} style={{ pointerEvents: window.location.href.includes(item.route) ? 'none' : 'auto' }} to={item.route}>
                <img src={item.img} alt={item.unit} />
              </NavLink>
            }) : renderUnitSlide()
          }
        </div>
      </div>
      <div className="specs" style={{ marginBottom: !slides && window.innerWidth < 768 ? '1rem' : '1rem'}}>
        {
          slides ? (
            <div className="slides">
              <Slider
                useText={false}
                slides={slides}
                rightButtonsStyle={{ right: '2rem', top: window.innerWidth > 768 ? '8rem' : '5rem' }}
                leftButtonsStyle={{ left: '10px', top: window.innerWidth > 768 ? '8rem' : '5rem' }}
                useBullets={true}
                slideNavStyle={addedStyle}
                slideNavBtnStyle={{ color: window.innerWidth > 768 ? '#fff' : '#000' }}
              />
            </div>
          ) : null
        }
        <img src={window.innerWidth > 768 ? planImg : planImgMobile} alt="plan" />
      </div>
    </div>
  )
}

export default UnitType