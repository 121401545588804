import React from 'react'
import './CTA.css'

const CTA = ({ text, showDownloadBrochure }) => {
  const handleDownload = () => {
    window.open('https://praja-group.com/Product%20Knowledge%20PT%20Praja%20Sakti%20Propertindo.pdf', '_blank')
  }

  return (
    <div className='cta'>
      <span>{text}</span>
      <div>
        {showDownloadBrochure ? <button onClick={handleDownload}>DOWNLOAD BROCHURE</button> : null}
      </div>
    </div>
  )
}

export default CTA