import React, { useState } from 'react'
import './FormContent.css'

const FormContent = ({ data }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    category: '',
    message: ''
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(JSON.stringify(formData))
    const pesan = `Halo, nama saya ${formData.name} dan nomor telepon saya ${formData.phone}. Saya ingin menanyakan tentang ${formData.category}. ${formData.message}`
    window.location.href = `mailto:info@prajasaktigroup.com?subject=Form Pesan&body=${pesan}`
    setFormData({
      name: '',
      phone: '',
      category: '',
      message: ''
    })
  }

  return (
    <div className='form-content'>
      <div className="left-form">
        <h3>
          Any Question? Leave your contact<br />
          and we'll call you back
        </h3>
        <span>Tinggalkan pesan Anda pada kolom form berikut ini dan dapatkan penawaran
        menarik dari kami.</span>
        <form onSubmit={handleSubmit}>
          <input type="text" id="name" name="name" placeholder='Nama' value={formData.name} onChange={handleInputChange} />
          <input type="tel" id="phone" name="phone" placeholder='No. Telepon' value={formData.phone} onChange={handleInputChange} />
          <select id="category" name="category" value={formData.category} onChange={handleInputChange}>
            <option value="">Pilihan:</option>
            <option value="Subsidi">Subsidi</option>
            <option value="Non Subsidi">Non Subsidi</option>
            <option value="Ruko">Ruko</option>
            <option value="Lainya">Lainya</option>
          </select>
          <textarea id="message" name="message" cols='40' rows='10' value={formData.message} onChange={handleInputChange} />
          <button className='button' type="submit">Send a message</button>
        </form>
      </div>
      <div className="right-form">
        <img src={data.image} alt={data.title} />
      </div>
    </div>
  )
}

export default FormContent