import slideImg1 from '../assets/images/KKA-1 - 2. Di Lingkungan Asri dan Telah Terhuni.png'
import slideImg2 from '../assets/images/KKA-1 - 2. Di Lingkungan Asri dan Telah Terhuni.jpg'
import slideImg3 from '../assets/images/KKA-1 - 3. Di Lingkungan Asri dan Telah Terhuni.jpg'
import slideImg4 from '../assets/images/KKA-1 - 4. Tersedia Unit Ready Stok.jpg'
import slideImg5 from '../assets/images/KKA-1 - 5. Tersedia Unit Ready Stok.jpg'
import slideImg6 from '../assets/images/KKA-1 - 6. Tersedia Unit Ready Stok.jpg'
import textBg from '../assets/images/Slider-Home17.png'
import LeaveBg from '../assets/images/leave-bg.jpg'
import Kka13672Img from '../assets/images/Blok C Type 36-72.jpg'
import Kka14584Img from '../assets/images/Blok A Type 45-84.jpg'
import PlanImg from '../assets/images/Spesifikasi-dan-Denah-KKA-1-Tipe-45-84-3.png'
import PlanImgMobile from '../assets/images/Spesifikasi-dan-Denah-KKA-1-Tipe-45-84-3-mobile.png'
import slideProject1 from '../assets/images/E1-construct.jpeg'
import slideProject2 from '../assets/images/e1-construct2.jpeg'
import slideProject3 from '../assets/images/e1-construct3.jpeg'

export const kka1 = {
    slides: [
        {
            img: slideImg1,
            text1: 'Bersama Mewujudkan Rumah Impian Anda',
            textBg: textBg
        },
        {
            img: slideImg2,
            text1: 'Di Lingkungan Asri dan Telah Terhuni',
            textBg: textBg
        },
        {
            img: slideImg3,
            text1: 'Di Lingkungan Asri dan Telah Terhuni',
            textBg: textBg
        },
        {
            img: slideImg4,
            text1: 'Tersedia Unit Ready Stok',
            textBg: textBg
        },
        {
            img: slideImg5,
            text1: 'Tersedia Unit Ready Stok',
            textBg: textBg
        },
        {
            img: slideImg6,
            text1: 'Tersedia Unit Ready Stok',
            textBg: textBg
        },
    ],
    achievementSection: {
        title: 'Project Achievement',
        sectionBg: LeaveBg,
        achievements: [
            {
                number: 98,
                postFix: '%',
                title: 'Sold',
                description: 'Rumah impian yang banyak diminati karena letaknya yang strategis dan harga yang terjangkau.'
            },
            {
                number: 700,
                title: 'Akad dalam 2 Tahun',
                description: 'Tercapai 700 unit akad kredit dalam 2 tahun pertama launching.'
            },
            {
                number: 90,
                postFix: '%',
                title: 'Tingkat Hunian',
                description: 'Perumahan yang berdampingan dengan hutan penelitian menjadikan area Kebun Kembang Asri sangat sejuk dan nyaman untuk dihuni. Tingkat hunian yang tinggi mencapai 90% dengan jumlah 630 KK dalam 2 tahun pertama launching.'
            }
        ]
    },
    unitTypes: [
        {
            img: Kka14584Img,
            unit: 'KKA 1 45/84',
            route: '/project/kka-1/type-45-84',
        },
        {
            img: Kka13672Img,
            unit: 'KKA 1 36/72',
            route: '/project/kka-1/type-36-72',
        },
    ],
    planImg: PlanImg,
    planImgMobile: PlanImgMobile,
    slideProjects: [
        {
            img: slideProject1,
            altText: 'e1-construction1'
        },
        {
            img: slideProject2,
            altText: 'e1-construction2'
        },
        {
            img: slideProject3,
            altText: 'e1-construction3'
        },
    ]
}