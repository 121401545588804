import { useEffect } from 'react'
import ProjectLayout from '../../../../layout/ProjectLayout'
import { kkaSubsidi2760 } from '../../../../data/kka-Subsidi2760'

export const TypeSubsidi2760 = () => {
  useEffect(() => {
    document.title = 'KEBUN KEMBANG ASRI 2 - Type Subsidi 27/60 - Website Praja'
  }, [])

  return (
    <ProjectLayout data={kkaSubsidi2760} />
  )
}
