import React from 'react'
import './Facilities.css'
import MesjidImg from '../../assets/images/1. Mushola.jpg'
import FutsalImg from '../../assets/images/futsal.jpg'
import JoggingImg from '../../assets/images/olahraga.jpg'
import PujaseraImg from '../../assets/images/2. Pujasera.jpg'
import TamanImg from '../../assets/images/3. Taman.jpg'
import CommercialImg from '../../assets/images/Town House.jpeg'

const Facilitites = () => {
  return (
    <div className='facilities-section'>
        <div className="title">
            <h2>Facilities of KKA</h2>
            <span>Tersedia Berbagai Fasilitas untuk Mendukung Aktivitas Anda dan Keluarga.</span>
        </div>
        <div className="cards">
            <div className="card">
                <img src={MesjidImg} alt="musholla" />
                <span>Musholla</span>
            </div>
            <div className="card">
                <img src={FutsalImg} alt="sport-center" />
                <span>Sport Center</span>
            </div>
            <div className="card">
                <img src={PujaseraImg} alt="pujasera" />
                <span>Pujasera</span>
            </div>
            <div className="card">
                <img src={TamanImg} alt="taman" />
                <span>Taman</span>
            </div>
            <div className="card">
                <img src={CommercialImg} alt="commercial" />
                <span>Commercial Area</span>
            </div>
        </div>
    </div>
  )
}

export default Facilitites