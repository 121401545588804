import React from 'react'
import { useEffect } from 'react'
import MainLayout from '../../layout/MainLayout'
import { home } from '../../data/home'

export const Homepage = () => {
  useEffect(() => {
    document.title = 'Home - Website Praja'
  }, [])

  return (
    <MainLayout pageTitle={'Home'} data={home} isUsingType />
  )
}
