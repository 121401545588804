import React from 'react'
import Map from '../../assets/images/Peta-Radius-Tambahan-10mnt.png'
// import LeaveImg from '../../assets/images/leave.png'
import './RadiusMap.css'

const RadiusMap = () => {
  return (
    <div className='radius-container'>
      {/* <img src={LeaveImg} className='leave' alt="leave" /> */}
      <div className="description">
          <h3>Kebun Kembang Asri</h3>
          <h1>Peta Radius 5KM</h1>
            <p>Ketahui letak perumahan kami yang berada di tengah kota Cikampek, Kabupaten Karawang, Jawa Barat, dekat dengan pintu tol, stasiun, kawasan industri, layanan kesehatan, pendidikan, pusat pembelanjaan dan lainnya.</p>
      </div>
      <div className="map">
          <img src={Map} alt="peta-radius" />
      </div>
    </div>
  )
}

export default RadiusMap